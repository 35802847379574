$enable_cookie-img: true !default;

@media only screen and (orientation: portrait) {
  .section-customer-service-contact-us {
    .customer-service-section {
      .customer-service-contact-form__category {
        padding: 1em 9px;
        letter-spacing: 0px;
        word-break: break-word;
      }
    }
  }
}

@media only screen and (orientation: landscape) {
  .section-customer-service-contact-us {
    .customer-service-section {
      li#category-products {
        padding: 25px 2px;
      }
      .customer-service-contact-form__category {
        padding: 1em 40px;
        letter-spacing: 2.9px;
      }
      li#category-service {
        padding: 1em 12px;
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .section-esearch {
    .product-grid {
      .product-thumb__abstract {
        .mobile-text {
          letter-spacing: 0em;
        }
        .product__button--add-to-bag {
          letter-spacing: 0em;
        }
      }
    }
    #search-wrapper {
      #search-results {
        .results-header {
          display: none;
        }
      }
      #product-results {
        float: none;
        border: none;
      }
    }
  }
}

.touch .shade-picker__color-name select {
  width: 100%;
  padding: 10px 30px 10px 0px;
  word-wrap: break-word;
  white-space: normal;
  height: auto;
  display: block;
  line-height: 20px;
}

.product-thumb {
  .product__button--add-to-bag {
    height: auto;
  }
  .product-thumb__cta {
    height: auto;
    line-height: 20px;
    padding: 5px 0px 5px 0px;
  }
}

.site-utils__nav {
  .menu__link--lvl-2,
  .menu__link--lvl-3 {
    height: auto;
  }
}

.site-header {
  .site-utils__links {
    .site-utils__menu {
      .site-utils__item--search {
        float: right;
      }
      .site-utils__item--stores {
        float: left;
      }
    }
  }
}

.makeup-lesson-page__slide-type {
  word-wrap: break-word;
  white-space: inherit;
}

.customer-service-menu {
  a.menu__link--lvl-1 {
    height: auto;
  }
}

@media only screen and (min-width: 320px) and (orientation: portrait) {
  .offers-details {
    .offers-details-item__button {
      word-wrap: break-word;
    }
  }
}

@media only screen and (min-width: 320px) and (orientation: portrait) {
  .offers-hero__container {
    .offers-hero__description .offers-hero__actions {
      a {
        width: 100%;
        text-align: center;
      }
    }
  }
  .offers-details {
    .offers-details-item {
      .offers-details-item__actions {
        .offers-details-item__link-container,
        .offers-details-item__button-container {
          width: 100%;
          a {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

.site-footer-contact__item--email {
  width: 100%;
  padding: 20px;
  a {
    border-right: none !important;
    padding: 0px !important;
  }
  span.icon-email {
    display: inline-block;
    margin-bottom: 0px;
  }
}

@media only screen and (orientation: portrait) {
  .profile-page {
    .profile-page__content {
      .optional-info__fieldset {
        .optional-info__item {
          #form--profile_preferences--field--BIRTH_MONTH {
            min-width: 134% !important;
          }
          #form--profile_preferences--field--BIRTH_YEAR {
            min-width: 153% !important;
            position: relative;
            margin-left: 7px !important;
          }
        }
      }
    }
  }
}

.node-elc-mpp {
  .page-header {
    background-color: #ffffff !important;
  }
}

div.thanks_header {
  font-size: 29px;
  line-height: 1.2em;
  border-bottom: 1px solid gray;
  padding-bottom: 18px;
}

div.thanks_message {
  padding-top: 21px;
}

.tiny-waitlist-overlay {
  h2 {
    border-bottom: 1px gray solid;
    padding-bottom: 18px;
    line-height: 1.2em;
  }
}

.tiny-waitlist-overlay {
  p.waitlist_message {
    margin-bottom: 16px;
  }
}

.product__notify-me {
  .product__notify-me-alert {
    .product__button {
      font-size: 13px;
      letter-spacing: 1px;
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}

div.thanks_header {
  font-size: 24px;
}
/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'Ja ';
              visibility: visible;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'Nein ';
              visibility: visible;
            }
          }
        }
      }
      @include breakpoint(($small-down) (orientation portrait)) {
        .pr-rd-footer {
          .pr-rd-helpful-action {
            margin-bottom: 0;
            .pr-helpful-btn {
              float: none;
            }
            .pr-helpful-no {
              .pr-helpful-count {
                &:after {
                  display: none;
                }
              }
            }
          }
          .pr-rd-flag-review-container {
            a {
              margin-left: 0;
            }
          }
          .pr-flag-review {
            .pr-flag-review-btn-cancel {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    #pr-war-form {
      .form-group {
        .input-add-tag {
          width: 85%;
          @include breakpoint(($small-down) (orientation portrait)) {
            width: 73%;
          }
        }
      }
      .pr-describeyourself-form-group {
        display: none;
      }
    }
  }
}

.product-full__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet {
          @include breakpoint(($small-down) (orientation portrait)) {
            .pr-snippet-read-and-write {
              display: inline;
              .pr-snippet-review-count {
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.product-full__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      a.pr-snippet-write-review-link {
        text-transform: initial;
      }
    }
    .pr-snippet {
      @if $cr22 {
        flex-wrap: wrap;
      }
    }
  }
}

section.bobbis-beauty-corner {
  .product-thumb__abstract {
    .product-thumb--price span.legal_msg {
      line-height: 15px;
      margin-bottom: 14px;
      margin-top: 12px;
    }
  }
}

.device-mobile {
  .account-mobile-landing-page.account__section {
    .profile-info__item {
      a.info-link {
        padding-left: 0px;
      }
    }
  }
  section.pg_wrapper.review.viewcart.checkout {
    form#checkout_review {
      a.info-link {
        padding-left: 0px;
      }
    }
  }
  section.pg_wrapper.shipping.viewcart.checkout {
    a.info-link {
      padding-left: 0px;
    }
  }
}

.payment_method_container p {
  margin-top: 8px;
}

#payment-type-cc {
  margin-top: 18px;
}

#payment-type-ncc span {
  .payment-type {
    margin-top: 9px;
  }
}

.lp_radio_button,
.lp_checkbox_button {
  input {
    display: none;
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

.touch .device-mobile {
  #lpChat .lp_lpview_content select {
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
    appearance: menulist;
  }
}

#lpChat {
  .lp_actions_bar_container {
    .lp_actions_bar {
      .lp_action_item {
        .lp_action_wrapper {
          .lp_title {
            text-transform: none;
          }
        }
      }
    }
  }
}

.profile-info__header {
  text-indent: inherit !important;
}

.optional-info {
  .optional-info__fieldset {
    padding: 0px;
  }
  .birthday {
    width: 100%;
    margin: -10px;
  }
}
