$disabled-color: #cccccc;
$disabled-cursor: text;

@mixin disable-button() {
  background-color: $disabled-color;
  cursor: $disabled-cursor;
}

.checkout {
  position: static;
  .checkout__sidebar {
    #offer-code-panel {
      margin-bottom: 10px;
      .offer-code-panel__content {
        margin-top: 0px;
        #offer_code .offer_code_box {
          em,
          span {
            display: block;
            margin-bottom: 10px;
          }
        }
      }
    }
    #links-panel {
      padding: 16px 20px;
      ul.links_list li {
        border: 0px;
      }
    }
  }
  h2.h_newsletter {
    display: none;
  }
  .checkout__content {
    #shipping-page-info-panel #checkout_shipping {
      p {
        margin: 0px 0px 15px 0px;
      }
      #shipping-address-info-panel,
      #billing-address-info-panel,
      #delivery-info-panel,
      #giftwrap-info-panel {
        margin-bottom: 15px;
      }
      #emailpromo-info-panel {
        h3.newsletter-info__header {
          text-indent: 0px;
          font-size: 14px;
        }
        p {
          margin-left: 0px;
        }
        .newsletter-info__item {
          padding-left: 0px;
          margin-left: 0px;
        }
      }
      #shipping-address-info-panel {
        #address_form_container {
          border: 0px;
          padding: 0px;
          select,
          input {
            margin-bottom: 0px;
          }
        }
        .address_controls {
          margin-bottom: 10px;
        }
        #choose-address {
          margin-bottom: 10px;
          select {
            margin: 10px 0px;
          }
        }
      }
      #billing-address-info-panel #billing_address_form_container {
        select,
        input {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.samples-page {
  .samples-panel__title {
    line-height: 25px;
  }
  .samples-buttons.bottom {
    display: block;
  }
}

#signin {
  .main .sign-in-page {
    .return-user {
      #signin input {
        width: 100%;
        margin-top: 10px;
      }
    }
    .new-account__item,
    .return-user__item {
      margin-bottom: 20px;
    }
    .new-account #registration_short input {
      width: 100%;
      margin-top: 10px;
    }
    .new-account {
      #checkout_registration_short {
        input {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}

.review {
  .checkout__content {
    .checkout-buttons-container {
      display: none;
    }
    #review-instructions p {
      margin: 15px 0px;
    }
    .shipping-panel,
    .viewcart-panel,
    .order-summary-panel {
      margin-bottom: 15px;
    }
  }
  #checkout_review {
    h3.newsletter-info__header {
      text-indent: 0px;
      font-size: 14px;
    }
    p {
      margin-left: 0px;
    }
    .newsletter-info__item {
      padding-left: 0px;
      margin-left: 0px;
    }
    .checkout-buttons-container {
      display: block;
      text-align: center;
      a {
        background-color: #ff4661;
        width: 100%;
      }
    }
  }
}

.billing {
  .checkout-buttons-container {
    display: block;
    margin: 15px 0px;
    text-align: center;
    a {
      background-color: #ff4661;
      width: 100%;
    }
  }
  #checkout_complete {
    margin-bottom: 15px;
    .checkout-buttons-container {
      display: none;
    }
  }
  #review-instructions p {
    margin: 15px 0px;
  }
  .shipping-panel,
  .viewcart-panel,
  .order-summary-panel {
    margin-bottom: 15px;
  }
  .payment-info-panel {
    .payment-type-panel {
      span {
        label {
          @include swap_direction(padding, 20px 0px);
          overflow: hidden;
          height: 60px;
          img {
            position: absolute;
            margin-top: -7px;
            #{$ldirection}: 30px;
          }
          &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_sofort_label {
            img {
              padding: 0px !important;
            }
          }
          &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_debit_label {
            img {
              margin-top: -20px;
            }
          }
          &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mc_label {
            img {
              margin-top: -20px;
            }
          }
          &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_visa_label {
            img {
              width: 60px;
            }
          }
        }
      }
    }
  }
}

.confirm {
  .checkout__content {
    div {
      margin-bottom: 10px;
    }
    #print-order {
      display: none;
      a {
        width: 100%;
      }
    }
    #confirmation-number a {
      margin-left: 10px;
    }
  }
}

.order-details {
  .order-products {
    .cart-item__desc {
      margin-bottom: 10px;
    }
    .cart-item__total {
      float: right;
      margin-top: 10px;
    }
  }
  .order-totals {
    table {
      th {
        width: 60%;
        text-align: right;
      }
      td {
        width: 40%;
        text-align: right;
      }
    }
  }
}

#viewcart-panel {
  .top-viewcart-buttons .viewcart-buttons-panel {
    .return-link a {
      display: block !important;
      visibility: visible;
    }
  }
}

.checkout__content {
  .order-summary-panel {
    border-top: 0px;
    margin-bottom: 15px;
    header {
      display: block;
      h2.order-summary-panel__title {
        text-align: left;
      }
    }
  }
}

.checkout {
  .checkout-buttons-container.trans_detail_item {
    display: block;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #d0d0d0;
    height: 80px;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    margin: 0px;
    input {
      display: block;
      margin: 25px auto 0 auto;
      width: 275px;
      background-color: #ff4661;
    }
    a {
      display: block;
      margin: 25px auto 0 auto;
      width: 275px !important;
      background-color: #ff4661;
    }
  }
}

.mobile-breadcrumb {
  margin: 10px 0px 10px 0px;
  display: block;
  ol.checkout-progress li {
    font-size: 12px;
    display: list-item;
    float: left;
    line-height: 1.4em;
    list-style-position: inside;
    list-style-type: decimal;
    margin: 0;
    padding: 3px 0;
    width: 50%;
    height: 40px;
  }
}

#shipping .checkout-progress__shipping,
#review .checkout-progress__review,
#billing .checkout-progress__billing,
#confirm .checkout-progress__confirm {
  font-weight: bold;
}

.checkout .checkout__content {
  #viewcart-panel {
    .viewcart-buttons-panel .continue-checkout.disabled {
      @include disable-button();
    }
  }
  #bottom-viewcart-buttons .viewcart-buttons-panel {
    .add-button .disabled.continue-checkout {
      @include disable-button();
    }
  }
  #checkout_review .checkout-buttons-container {
    #continue-checkout-btm a.disabled {
      @include disable-button();
    }
  }
}

#colorbox {
  .waitlist-iframe-wrapper {
    width: 100%;
    height: 95%;
  }
}

.tiny-waitlist-overlay h2 {
  margin: 0 0 15px 0;
  text-align: left;
}

.tiny-waitlist-overlay p.waitlist_message {
  margin-bottom: 10px;
}

.tiny-waitlist-overlay .field {
  border-top: 1px solid gray;
  padding-top: 10px;
}

.tiny-waitlist-overlay .email_input {
  display: inline-block;
}

.tiny-waitlist-overlay .email_input input[type='text'] {
  width: 100%;
  margin-bottom: 10px;
}

.tiny-waitlist-overlay .field .align-r {
  display: inline-block;
  margin-left: 10px;
}

.shipping-panel__title {
  text-indent: 0px;
  line-height: inherit;
  padding: 15px;
}

.viewcart {
  .checkout__content {
    #viewcart-panel .viewcart-panel__content {
      margin-top: 15px;
      .empty-cart {
        h4.checkout__subtitle {
          padding-left: 20px;
        }
      }
      .signin-to-see-cart {
        margin-left: 20px;
      }
    }
  }
}

#samples-panel,
#checkout_samples {
  .samples {
    .deactivate {
      .sample-select-button,
      .sample-select-button:hover {
        opacity: 0.3;
        cursor: default;
        background-color: #000000;
        border-color: #d4d4d4;
      }
    }
  }
}

.samples-page {
  .samples-panel {
    .sample-select-button:hover {
      background: #000000;
      border-color: #000000;
    }
    .selected,
    .selected:hover {
      background: #ff4661;
      border-color: #ff4661;
    }
  }
}

#shipping,
#viewcart,
#samples,
#review,
#billing,
#confirm,
#payment {
  .site-footer {
    .sticky-offer {
      display: none;
      visibility: hidden;
    }
  }
}

@media only screen and (max-width: 380px) {
  .billing {
    #checkout-wrapper .payment_frame {
      iframe {
        width: 100%;
      }
    }
  }
}

#checkout_review {
  .newsletter-info__item {
    margin-top: -23px;
  }
}

#checkout_shipping {
  .newsletter-info__header {
    font-size: 14px;
    margin-left: -18px;
    margin-top: 16px;
  }
  .newsletter-info__item {
    padding-left: 0px;
    margin-left: 0px;
  }
}

.device-mobile {
  section.pg_wrapper.shipping.viewcart.checkout {
    #delivery-options {
      padding-bottom: 8px;
    }
    h2.h_newsletter {
      padding-top: 13px;
    }
    section.newsletter-info {
      h3.newsletter-info__header {
        text-indent: 0px;
      }
      p.newsletter-info__text--new {
        margin-bottom: 0px;
      }
      .newsletter-info__item {
        margin: 0 0 0 -9px;
      }
    }
  }
}

body#shipping.device-mobile {
  #checkout_shipping {
    .newsletter-info__header {
      margin-left: 0px;
    }
  }
}

#payment {
  .error_messages li {
    margin-left: 0px;
  }
}

.checkout {
  .address_form_container {
    .phone_label_container {
      padding-left: 10px;
    }
  }
}

.opc__shipping {
  #address_form_container {
    border: none;
    border-bottom: 1px solid $color-black;
  }
}

#viewcart {
  &.opc__cart {
    .checkout {
      #order-summary-panel {
        #delivery-options {
          .ship-method-group {
            > span {
              width: 100%;
              display: block;
            }
          }
          .shipmethod-item-price {
            display: none;
          }
        }
      }
    }
  }
}

.opc__payment {
  .payment-info-panel {
    .payment-type-panel {
      .payment-option-container {
        .card-payment-block {
          .checkout__panel-title {
            font-size: 14px;
          }
        }
        .card-payment-content {
          .group1 {
            font-size: 11px;
            img {
              @include swap_direction(margin, -3px 0 0 0 !important);
            }
          }
        }
      }
      span {
        label {
          @include swap_direction(padding, 8px 0);
          height: 40px;
          img {
            position: static;
            width: 45px !important;
            float: $rdirection;
            height: 35px;
            overflow: visible;
          }
        }
      }
    }
  }
}

.pg-checkout {
  .site-footer-bottom {
    background: $color-gray-background;
    &__menu {
      .menu__item {
        a {
          color: $color-black;
        }
      }
    }
    &__trademark {
      color: $color-black;
    }
  }
}
