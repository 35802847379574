.product-foundation-vto {
  &__container {
    ul.shade-list-vto li a.swatch {
      background-color: $color-white;
    }
    .product-foundation-vto__undertone {
      @include breakpoint($mobileMedium) {
        top: 0;
      }
    }
  }
  &__info-container {
    width: 100%;
  }
  &__undertone-importance-copy {
    padding-bottom: 10px;
  }
  &__btn-container {
    min-height: 46px;
    .see-all-shades,
    .see-my-perfect {
      padding-top: 0;
    }
  }
}
