.account__section {
  margin: 0px;
  padding: 20px;
  .profile-page__head {
    padding: 10px;
    h1.profile-page__header {
      margin: 0px;
    }
  }
  #profile_preferences {
    .optional-info {
      padding: 10px;
      overflow: hidden;
    }
    .newsletter-info {
      padding: 4px;
      p {
        margin-bottom: 15px;
        margin-left: 0px;
      }
      .newsletter-info__item {
        margin-left: 0px;
        padding-left: 6px;
      }
    }
  }
}

.password-sent-page {
  .sent-info__text {
    margin: 10px 0px;
  }
}

.password-reset-page {
  .password-reset__item {
    margin: 10px;
    input {
      width: 100%;
    }
  }
  #password_reset {
    .password-reset__submit {
      margin: 10px;
    }
  }
}

h1.address-book-page__header span,
h2.account-landing-profile__header span {
  background: none repeat scroll 0 0 #ffffff;
  display: inline-block;
  padding: 0 7px 0 9px;
  position: relative;
}

.address-book-page {
  .address-book,
  .payment-info {
    margin: 0px;
    padding: 0px 20px 20px 20px;
    width: auto;
  }
  .address-item__controls a {
    margin-right: 10px;
  }
}

.past-purchases-page__header-container {
  margin-top: 40px;
}

.shipping-billing {
  margin-top: 40px;
  padding: 0px 20px 20px 20px;
  .address-form {
    h1.address-form__header {
      margin: 10px 0px 10px 0px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .required_field_notice {
    padding-left: 10px;
  }
}

.address-item__controls a {
  line-height: 25px;
  border-bottom: 1px solid #ff4661;
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'Brandon Text Bold';
  font-size: 11px;
}

.upload-file__imposter {
  background-color: #ff4661;
  color: #fff;
  width: 140px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px;
  font-weight: bold;
}

.upload-file__value {
  text-transform: uppercase;
  border-bottom: 1px solid;
  font-weight: bold;
  font-size: 14px;
}

.profile-page .profile-page__content {
  #profile_preferences .optional-info__fieldset {
    label {
      display: block;
    }
    .optional-info__item {
      margin: 0 2% 5px 0;
      width: 30%;
      .field {
        min-width: 110%;
      }
    }
  }
}

.favorites-page .favorites-page__content {
  padding: 15px;
  h2.favorites__instruction-step-title {
    line-height: 35px;
  }
}

.shipping-billing .address-form {
  #address .address-form__fieldset {
    #form--address--field--TITLE {
      width: 100%;
    }
  }
}

.past-purchases-page {
  .past-purchases-page__header {
    span {
      background: #ffffff;
      display: inline-block;
      position: relative;
    }
  }
  .past-purchases {
    .product-item {
      padding-top: 15px;
      margin-bottom: 15px;
      border-top: 1px #bbbbb9 solid;
      .product-item__img img {
        margin: 0px auto;
        display: block;
      }
      .product-item__details {
        div,
        h4 {
          text-align: center;
        }
      }
      .product-item__list li {
        text-align: center;
        .product-item__swatch {
          display: inline-block;
        }
        .product-item__shade {
          display: inline-block;
          vertical-align: top;
          line-height: 21px;
        }
      }
      .product-item__name a {
        font-family: 'Brandon Text Bold';
        font-weight: normal;
        font-size: 11px;
        line-height: 19px;
        letter-spacing: 0.22em;
        text-transform: uppercase;
        color: black;
        text-decoration: none;
      }
      .product-item__add-to-cart {
        margin-top: 20px;
      }
    }
    .product-item:first-child {
      border: none;
    }
  }
}

#profile_preferences {
  .newsletter-info__item {
    margin-top: -10px;
  }
  .newsletter-info__header {
    margin-top: -21px;
    margin-left: 6px;
  }
}

.account-mobile-landing-page.account__section {
  .profile-page {
    h3.profile-info__header {
      text-indent: 0px;
    }
    section.newsletter-info {
      h3.newsletter-info__header {
        text-indent: 0px;
      }
      p.newsletter-info__text--new {
        margin-bottom: 0px;
      }
    }
    section.optional-info {
      h3.optional-info__header {
        padding: 0px 0px;
        padding-bottom: 14px;
      }
      .birthday {
        padding-top: 10px;
      }
    }
  }
}
