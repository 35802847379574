.hot-collection {
  .product_collection_hero__content-item {
    .button--disabled {
      background-color: #ccc;
      border: 1px solid #ccc;
    }
    .js-quickshop-container {
      .quickshop {
        .product {
          .product__details {
            .product__actions {
              padding: 5px 0 0 10px;
              .product__button {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .block-template-product-collection-prodcut-p-v1 {
      .content {
        .product-palette-atb {
          .product__button--add-to-bag {
            width: 80%;
          }
        }
      }
    }
  }
}

section.mpp__content {
  .product-thumb__abstract {
    .product-thumb--price span.legal_msg {
      line-height: 15px;
      margin-bottom: 14px;
      margin-top: 12px;
    }
  }
}

.spp-mobile-page {
  .product--full .product__header .mobile-price {
    float: left !important;
    margin-top: 6px;
  }
}

.popup-offer__footer p {
  margin-bottom: 12px;
}

.js-popup-offer-overlay-cbox {
  .popup-offer.js-popup-offer {
    .popup-offer__outer-wrap.popup-offer__sign-up {
      .popup-offer__body p {
        margin: 0 0 10px 0;
      }
      h2.popup-offer__heading {
        margin-bottom: 10px;
      }
    }
  }
}
